<template>
  <div>
    <Header class="responsive-bar">
      <template v-slot:title>
        {{ $t("upload-documents") }}
      </template>
    </Header>
    <div>
      <div
        class="upload-document max-w-3xl m-auto bg-white shadow rounded-md sm:mt-5"
      >
        <div class="pb-5 border-b border-gray-200">
          <div class="flex justify-between">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ $t("upload-documents") }}
            </h3>
          </div>
          <p class="mt-1 text-sm leading-5 text-gray-500">
            {{
              $t(
                "please-provide-us-with-the-following-documents-upload-front-and-back-of-id-cards-e-g-driver-licence"
              )
            }}
            <br />
            {{
              $t(
                "files-should-be-in-pdf-jpg-or-png-format-and-must-not-exceed-5mb"
              )
            }}
          </p>
        </div>
        <div v-if="errorSize.value">
          <span class="text-sm text-red-600 h-4">{{
            $t(errorSize.message).replace(": .", ": 5bm.")
          }}</span>
        </div>
        <div v-if="errorType.value">
          <span class="text-sm text-red-600 h-4">{{
            $t(errorType.message)
          }}</span>
        </div>
        <p
          v-if="get_ib_upload_documents.length > 0"
          class="text-md leading-5 font-normal mt-3"
        >
          {{ $t("uploaded-documents") }}
        </p>
        <div class="my-5 border rounded-md">
          <div
            v-for="(document, index) in get_ib_upload_documents.results"
            :key="index"
            :class="index === 0 ? '' : 'border-t'"
            class="p-2 text-sm text-gray-700 flex"
          >
            <svg
              class="w-5 h-5 mr-3 text-gray-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
            {{ document.doc_name }}
          </div>
        </div>
        <div v-if="whitelabel !== 'GCCBrokers'" class="my-3">
          <dt class="text-sm leading-5 font-normal">
            {{ $t("document-name") }}
          </dt>
          <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0">
            <div>
              <ValidationProvider name="leverage" rules="required">
                <input
                  v-model="document_name"
                  class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  @change="error.value = false"
                />
                <span v-if="error.value" class="text-sm text-red-600 h-4">{{
                  error.message
                }}</span>
                <span v-if="errorName.value" class="text-sm text-red-600 h-4">{{
                  errorName.message
                }}</span>
              </ValidationProvider>
            </div>
          </dd>
        </div>
        <div class="pt-4">
          <div
            class="box flex justify-center p-3 border-2 border-gray-300 border-dashed rounded-md"
          >
            <div
              class="text-center flex"
              @drop="onFileChanged($event, true)"
              @dragover.prevent
            >
              <div class="m-auto w-28">
                <svg
                  class="mx-auto h-12 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 48 48"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>
                <p class="mt-1 text-sm text-gray-600">
                  <label
                    class="font-medium text-indigo-600 transition duration-150 ease-in-out"
                    for="file"
                    >{{ $t("Upload") }}</label
                  >
                  <input
                    id="file"
                    ref="fileUploader"
                    accept="image/png, image/jpeg, application/pdf"
                    class="hidden"
                    type="file"
                    @change="onFileChanged($event, false)"
                    @click="resetFileUploader"
                  />
                  <span>
                    <span>
                      {{ $t("a-file") }}
                    </span>
                    <br />
                    {{ $t("or-drag-and-drop") }}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Header from "@/components/Header";

export default {
  name: "IbUploadDocuments",
  components: {
    Header,
  },
  data() {
    return {
      file: "",
      document_name: "",
      error: {
        value: false,
        message: "",
      },
      errorSize: {
        value: false,
        message: "file-is-too-big-filesize-max-filesize-maxfilesize",
      },
      errorType: {
        value: false,
        message:
          "files-should-be-in-pdf-jpg-or-png-format-and-must-not-exceed-5mb",
      },
      errorName: {
        value: false,
        message: "",
      },
      whitelabel: process.env.VUE_APP_WHITELABEL,
    };
  },
  computed: {
    ...mapGetters(["get_ib_upload_documents"]),
  },
  created() {
    this.$store.dispatch("set_ib_upload_documents");
  },
  methods: {
    // we need to reset file uploader so input will detect @change if same file is selected after invalid "document name" input
    resetFileUploader() {
      this.$refs.fileUploader.value = "";
    },
    onFileChanged(event, isDrop) {
      this.errorType.value = false;
      this.errorSize.value = false;
      this.errorName.value = false;
      if (this.document_name.length === 0 && this.whitelabel !== "GCCBrokers") {
        const t = this.$t.bind(this);
        this.error.value = true;
        this.error.message = t("this-field-is-required");
      } else {
        event.stopPropagation();
        event.preventDefault();
        let formData = new FormData();

        if (isDrop) {
          this.file = event.dataTransfer.files[0];
          if (this.file.size > 5242880) {
            this.errorSize.value = true;
            return;
          }
          const validImageTypes = [
            "application/pdf",
            "image/jpeg",
            "image/png",
          ];
          if (!validImageTypes.includes(this.file.type)) {
            this.errorType.value = true;
            return;
          }
        } else {
          this.file = event.target.files[0];
          if (this.file.size > 5242880) {
            this.errorSize.value = true;
            return;
          }
          const validImageTypes = [
            "application/pdf",
            "image/jpeg",
            "image/png",
          ];
          if (!validImageTypes.includes(this.file.type)) {
            this.errorType.value = true;
            return;
          }
        }
        if (this.whitelabel === "GCCBrokers") {
          formData.append("doc_name", this.file.name);
        } else {
          formData.append("doc_name", this.document_name);
        }
        formData.append("file", this.file);
        formData.append("name", this.file);
        this.$store
          .dispatch("ib_upload_documents", formData)
          .then(() => {
            this.$notify({
              group: "foo",
              text: `${this.$t("uploaded-documents")}`,
            });
            this.$store.dispatch("set_ib_upload_documents");
            this.error.value = false;
          })
          .catch((err) => {
            if (
              err.data.doc_name[0] ===
              "Ensure this field has no more than 200 characters."
            ) {
              this.errorName = {
                value: true,
                message: err.data.doc_name[0],
              };
            } else {
              this.$notify({
                group: "foo",
                text: `${Object.entries(err.data)[0][1]}`,
                type: "warn",
              });
            }
          });
      }
    },
  },
};
</script>